<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row>
      <v-col cols="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch"
          searchLabel="Search by tag name..."
        />
      </v-col>
      <v-col align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="msaBlue"
              class="white--text"
              v-bind="attrs"
              v-on="on"
              @click="goToDetail()"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Form Tag</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <AddFormTagGroupDialog @tagGroupAdded="onTagGroupAdded">
        </AddFormTagGroupDialog>
      </v-col>
      <v-col class="shrink" v-if="groups.length > 1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              :disabled="!canChangeOrders"
              @click="$refs.changeOrders.show()"
            >
              <v-icon> mdi-folder-arrow-up-down-outline </v-icon>
            </v-btn>
          </template>
          <span>Order Groups</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <FormTagsFilter
          ref="filters"
          :filters="filters"
          @applyFilters="onApplyFilter"
        ></FormTagsFilter>
      </v-col>
      <v-col class="shrink">
        <ExportFormTags :filters="filters"> </ExportFormTags>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="resultMessage.length" class="text-center">
          {{ resultMessage }}
        </div>
        <v-expansion-panels
          transition="fade-transition"
          v-model="expanded"
          multiple
          active-class="msaBlue white--text"
        >
          <v-expansion-panel
            v-for="(group, index) in groups"
            :key="index"
            class="my-2"
          >
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(index)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink" :cols="isMobile ? 1 : false">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col
                  class="grow pl-4 font-weight-medium"
                  :cols="isMobile ? 11 : false"
                >
                  <span>{{ group.name }}</span>
                  <v-badge
                    inline
                    class="ml-2"
                    :bordered="open"
                    :content="group.count || '0'"
                    :value="group.count || '0'"
                  >
                  </v-badge>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right">
                  <v-row dense>
                    <v-spacer></v-spacer>
                    <v-col>
                      <EditFormTagGroupDialog
                        :group="group"
                        :isGroupExpanded="open"
                        @groupUpdated="onGroupUpdated"
                      />
                    </v-col>
                    <v-col class="shrink">
                      <DeleteFormTagGroupDialog
                        v-if="!group.isDefault"
                        :group="group"
                        :isGroupExpanded="open"
                        @groupDelete="onGroupDelete(group)"
                      ></DeleteFormTagGroupDialog>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-4 white">
              <FormTagsTable
                :key="tableKey"
                :filter="filters"
                :selected="selected"
                :groupId="group.id"
                :canChangeOrders="canChangeOrders"
                @onSelect="onSelect($event)"
                @archive="onArchive"
                @activate="onActivate"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <FormTagsSelectDialog
      :groups="groups"
      :selectedItems="selected"
      v-if="selected.length > 0"
      @onUnselect="handleUnselect($event)"
      @updateSelected="refreshGroups"
      @moveSelected="refreshGroups"
      @cancel="selected = []"
    >
    </FormTagsSelectDialog>

    <ChangeOrdersDialog
      ref="changeOrders"
      :items="groups"
      itemType="groups"
      @reloadList="getGroups()"
    />
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import DeleteFormTagGroupDialog from '@/components/FormTags/DeleteFormTagGroupDialog.vue';
import AddFormTagGroupDialog from '@/components/FormTags/AddFormTagGroupDialog.vue';
import EditFormTagGroupDialog from '@/components/FormTags/EditFormTagGroupDialog.vue';
import FormTagsTable from '@/components/FormTags/FormTagsTable.vue';
import FormTagsSelectDialog from '@/components/FormTags/FormTagsSelectDialog.vue';
import FormTagsFilter from '@/components/FormTags/FormTagsFilter.vue';
import ExportFormTags from '@/components/FormTags/ExportFormTags.vue';
import ChangeOrdersDialog from '@/components/PrivateDocumentLibrary/ChangeOrdersDialog.vue';

export default {
  name: 'FormTagsPage',
  components: {
    SearchBar,
    DeleteFormTagGroupDialog,
    AddFormTagGroupDialog,
    EditFormTagGroupDialog,
    FormTagsTable,
    FormTagsSelectDialog,
    FormTagsFilter,
    ExportFormTags,
    ChangeOrdersDialog,
  },
  data() {
    return {
      groups: [],
      expanded: [],
      savedPanelState: [],
      selected: [],
      tableKey: 0,
      isLoading: false,
      filters: {
        ...this.$constants.FORM_TAGS_FILTER,
      },
    };
  },
  computed: {
    isNew() {
      return this.tag.id == -1;
    },
    resultMessage() {
      if (!this.isLoading && !this.groups.length) {
        return 'No results found';
      }
      return '';
    },
    headerStyles() {
      return (index) =>
        this.expanded.findIndex((i) => i == index) == -1
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            }
          : {};
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    canChangeOrders() {
      return (
        JSON.stringify(this.filters) ==
        JSON.stringify(this.$constants.FORM_TAGS_FILTER)
      );
    },
  },
  methods: {
    onSearch(data) {
      this.filters.name = data;
      this.applyFilter();
    },
    onApplyFilter(filters) {
      this.filters = filters;
      this.$refs.searchBar.search = filters.name;
      this.applyFilter();
    },
    onArchive(groupId) {
      const index = this.groups.findIndex((g) => g.id == groupId);
      if (index != -1) {
        this.groups[index].count--;
      }
    },
    onActivate(groupId) {
      const index = this.groups.findIndex((g) => g.id == groupId);
      if (index != -1) {
        this.groups[index].count--;
      }
    },
    onGroupUpdated(data) {
      const index = this.groups.findIndex((g) => g.id == data.id);
      if (index != -1) {
        this.groups[index].name = data.name;
      }
    },
    loadPanelState() {
      const expanded = [];
      this.savedPanelState.forEach((id) => {
        const index = this.groups.findIndex((g) => g.id == id);
        if (index != -1) {
          expanded.push(index);
        }
      });
      this.expanded = expanded;
    },
    savePanelState() {
      this.savedPanelState = this.expanded.map((index) => {
        return this.groups[index].id;
      });
    },

    onTagGroupAdded() {
      if (this.filters.name.trim() != '') {
        this.$refs.searchBar.clearSearch();
        return;
      }
      this.getGroups();
    },
    goToDetail() {
      //this is here so the crumbs wont look wonky on the next page
      this.$store.commit('setSelectedTagName', 'New Tag');
      this.$router.push({
        name: 'FormTagDetail',
        params: { id: 0 },
      });
    },
    getGroups() {
      this.isLoading = true;
      this.savePanelState();
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
      };
      const url = 'get-tag-groups-with-count?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data;
          this.loadPanelState();
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
    },
    onGroupDelete(group) {
      const index = this.groups.findIndex((g) => g.id == group.id);
      this.groups.splice(index, 1);
    },
    onSelect(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(event.items)
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) => !event.items.some((t) => t.id == selected.id),
        );
      }
    },
    handleUnselect(event) {
      this.selected = this.selected.filter(
        (selected) => selected.id != event.id,
      );
    },
    refreshGroups() {
      this.selected = [];
      this.getGroups().then(() => {
        this.refreshOpenPanels();
      });
    },
    refreshOpenPanels() {
      this.tableKey++;
    },
    getGroupIdsParams() {
      if (
        this.$route.query.tagGroupIds &&
        this.$route.query.tagGroupIds.length
      ) {
        let items = this.$route.query.tagGroupIds.split(',');
        return items.map((i) => parseInt(i));
      }
      return [];
    },
    loadQueryParams() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.$refs.searchBar.search = this.filters.name;
      this.$refs.filters.setFilters(this.filters);
      this.getGroups();
    },
    applyFilter() {
      let params = this.$helpers.serializeFilter(this.filters);
      this.$router
        .push({
          name: this.$route.name,
          query: params,
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadQueryParams();
  },
};
</script>
