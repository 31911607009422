<template>
  <v-dialog v-model="dialog" width="1000px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="msaBlue"
        overlap
        :content="selectedItems.length"
        :value="selectedItems.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px
        }`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Tags
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Edit Tags </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form ref="form">
        <v-card-text class="pt-4">
          <v-row align="center">
            <v-col class="shrink pr-0">
              <v-icon>mdi-folder-outline</v-icon>
            </v-col>
            <v-col class="grow">
              <CustomizedAutoComplete
                :value="globalGroupId"
                hide-details
                :items="groups"
                label="Select a group to move tags to"
                @change="
                  globalGroupId = $event;
                  applyGlobalGroup();
                "
                clearable
              />
            </v-col>
            <v-col cols="12" v-if="!isMobile">
              <v-card>
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="lightBg">
                      <tr>
                        <th class="text-left">Selected Tags</th>
                        <th>Group</th>
                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in edited" :key="item.id">
                        <td>
                          <span
                            :class="{
                              'msaBlue--text': !item.isArchive,
                              'warning--text': item.isArchive,
                            }"
                            >{{ item.name }}</span
                          >
                        </td>
                        <td :style="{ width: '350px' }">
                          <CustomizedAutoComplete
                            :rules="rules"
                            :value="item.tagGroupId"
                            hide-details
                            :items="groups"
                            @change="
                              globalGroupId = null;
                              item.tagGroupId = $event;
                            "
                            label="Select a group"
                          />
                        </td>
                        <td :style="{ width: '60px' }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                color="red"
                                @click="unselect(item)"
                                v-on="on"
                              >
                                <v-icon> mdi-minus-circle-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>Remove From List</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col v-if="isMobile" cols="12">
              <v-card v-for="item in edited" :key="item.id" class="mb-4">
                <v-card-title class="text-body-1 msaGrey">
                  {{ item.name }}
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-row no-gutters>
                    <v-col class="shrink" cols="9">
                      <CustomizedAutoComplete
                        :rules="rules"
                        :value="item.tagGroupId"
                        hide-details
                        :items="groups"
                        @change="
                          globalGroupId = null;
                          item.tagGroupId = $event;
                        "
                        label="Select a group"
                      />
                    </v-col>
                    <v-col class="shrink ml-4" cols="2">
                      <v-btn icon color="red" @click="unselect(item)">
                        <v-icon> mdi-minus-circle-outline </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-row dense>
          <v-col :cols="isMobile ? 12 : undefined">
            <v-btn class="msaBlue white--text mr-4 mb-2" @click="activateAll()">
              Activate All Tags
            </v-btn>
            <v-btn class="warning white--text mb-2" @click="archiveAll()">
              Archive All Tags
            </v-btn>
          </v-col>
          <v-col
            :align="isMobile ? 'left' : 'right'"
            :cols="isMobile ? 12 : undefined"
          >
            <v-btn text color="red" class="mb-2" @click="cancel()">
              Cancel</v-btn
            >
            <v-btn class="msaBlue white--text mb-2" @click="validate">
              Move Tags
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      globalGroupId: null,
      groups: [],
      edited: [],
      nonEdited: [],
      rules: [(v) => !!v || 'This field is required'],
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        this.getTagGroups();
        this.edited = JSON.parse(JSON.stringify(this.selectedItems));
        this.nonEdited = JSON.parse(JSON.stringify(this.selectedItems));
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 292 / 2;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.confirm();
      }
    },
    confirm() {
      const params = {
        loaderText: 'Saving...',
        tags: this.edited.map((item) => {
          return { id: item.id, tagGroupId: item.tagGroupId };
        }),
      };

      const url = 'move-tags?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('moveSelected');
          this.close();
        })
        .catch((error) => error);
    },
    unselect(item) {
      this.$emit('onUnselect', item);
      const index = this.edited.findIndex((i) => i.id == item.id);
      this.edited.splice(index, 1);
      if (this.edited.length == 0) {
        this.close();
      }
    },
    close() {
      this.dialog = false;
    },
    activateAll() {
      const itemIds = this.edited.map((i) => i.id);
      const params = {
        loaderText: 'Activating tags...',
        tagIds: itemIds,
      };
      const url = 'activate-tags?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('updateSelected');
          this.close();
        })
        .catch((error) => error);
    },
    archiveAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        const itemIds = this.edited.map((i) => i.id);
        const params = {
          loaderText: 'Archiving tags...',
          tagIds: itemIds,
        };
        const url = 'archive-tags?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('updateSelected');
            this.close();
          })
          .catch((error) => error);
      };
    },
    applyGlobalGroup() {
      if (!this.globalGroupId) {
        this.edited = [...this.nonEdited];
        return;
      }

      this.edited.forEach((tag, index) => {
        this.$set(this.edited, index, {
          ...tag,
          tagGroupId: this.globalGroupId,
        });
      });
    },
    getTagGroups() {
      this.$axios.post('get-tag-groups?format=json', {}).then((response) => {
        this.groups = response.data;
      });
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
  },
};
</script>

<style>
/* override publish button disabled style */
#publish-btn-toggle .v-btn--disabled.publish-btn {
  background-color: #3564ac !important;
  color: white !important;
}

#publish-btn-toggle .v-btn--disabled.publish-btn .publish-icon {
  color: #4caf50 !important;
}
</style>
<style lang="scss" scoped>
.v-select:v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
