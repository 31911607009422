var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"1000px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{style:(`position: fixed; bottom: 20px; z-index: 80; left: ${_vm.floatBtnLeftPosition}px
      }`),attrs:{"color":"msaBlue","overlap":"","content":_vm.selectedItems.length,"value":_vm.selectedItems.length}},[_c('v-btn',_vm._g(_vm._b({staticClass:"amber lighten-1 msaBlue--text",attrs:{"elevation":"5","large":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2"},[_vm._v(" mdi-file-document-multiple-outline ")]),_vm._v(" Selected Tags ")],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"msaBlue white--text"},[_c('v-row',[_c('v-col',[_vm._v(" Edit Tags ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-form',{ref:"form"},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink pr-0"},[_c('v-icon',[_vm._v("mdi-folder-outline")])],1),_c('v-col',{staticClass:"grow"},[_c('CustomizedAutoComplete',{attrs:{"value":_vm.globalGroupId,"hide-details":"","items":_vm.groups,"label":"Select a group to move tags to","clearable":""},on:{"change":function($event){_vm.globalGroupId = $event;
                _vm.applyGlobalGroup();}}})],1),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"lightBg"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Selected Tags")]),_c('th',[_vm._v("Group")]),_c('th',{staticClass:"text-center"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.edited),function(item){return _c('tr',{key:item.id},[_c('td',[_c('span',{class:{
                            'msaBlue--text': !item.isArchive,
                            'warning--text': item.isArchive,
                          }},[_vm._v(_vm._s(item.name))])]),_c('td',{style:({ width: '350px' })},[_c('CustomizedAutoComplete',{attrs:{"rules":_vm.rules,"value":item.tagGroupId,"hide-details":"","items":_vm.groups,"label":"Select a group"},on:{"change":function($event){_vm.globalGroupId = null;
                            item.tagGroupId = $event;}}})],1),_c('td',{style:({ width: '60px' })},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.unselect(item)}}},on),[_c('v-icon',[_vm._v(" mdi-minus-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove From List")])])],1)])}),0)]},proxy:true}],null,false,2638171069)})],1)],1):_vm._e(),(_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.edited),function(item){return _c('v-card',{key:item.id,staticClass:"mb-4"},[_c('v-card-title',{staticClass:"text-body-1 msaGrey"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink",attrs:{"cols":"9"}},[_c('CustomizedAutoComplete',{attrs:{"rules":_vm.rules,"value":item.tagGroupId,"hide-details":"","items":_vm.groups,"label":"Select a group"},on:{"change":function($event){_vm.globalGroupId = null;
                        item.tagGroupId = $event;}}})],1),_c('v-col',{staticClass:"shrink ml-4",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.unselect(item)}}},[_c('v-icon',[_vm._v(" mdi-minus-circle-outline ")])],1)],1)],1)],1)],1)}),1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : undefined}},[_c('v-btn',{staticClass:"msaBlue white--text mr-4 mb-2",on:{"click":function($event){return _vm.activateAll()}}},[_vm._v(" Activate All Tags ")]),_c('v-btn',{staticClass:"warning white--text mb-2",on:{"click":function($event){return _vm.archiveAll()}}},[_vm._v(" Archive All Tags ")])],1),_c('v-col',{attrs:{"align":_vm.isMobile ? 'left' : 'right',"cols":_vm.isMobile ? 12 : undefined}},[_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel")]),_c('v-btn',{staticClass:"msaBlue white--text mb-2",on:{"click":_vm.validate}},[_vm._v(" Move Tags ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }