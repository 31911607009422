<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row class="ma-1">
            <span> Filter Form Tags </span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12"> Tag Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.name"
                outlined
                hide-details
                placeholder="Enter tag name"
                dense
                clearable
                @click:clear="onInputClear('name')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Tag Group(s) </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="filters.tagGroupIds"
                label="Select a group"
                hide-details
                :items="groups"
                clearable
                multiple
                deletable-chips
                @change="filters.tagGroupIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.archived"
                mandatory
                dense
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize">
                  Active
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize">
                  Archived
                </v-btn>
                <v-btn style="width: 34%" class="text-capitalize"> All </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="isFilterApplied ? 'orange' : 'msaBlue'"
          dark
          v-blur
          v-bind="attrs"
          v-on="on"
          @click="show"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filter</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groups: [],
      dialog: false,
      filters: { ...this.$constants.FORM_TAGS_FILTER },
      original: { ...this.$constants.FORM_TAGS_FILTER },
      isFilterApplied: false,
    };
  },
  methods: {
    onInputClear(element) {
      this.filters[element] = '';
    },
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.updateFilterButtonState();
    },
    getTagGroups() {
      this.$axios.post('get-tag-groups?format=json', {}).then((response) => {
        this.groups = response.data;
      });
    },
    close() {
      this.filters = { ...this.orginal };
      this.dialog = false;
    },
    show() {
      this.orginal = { ...this.filters };
      this.dialog = true;
    },
    clear() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.FORM_TAGS_FILTER),
      );
      this.apply();
    },
    apply() {
      this.filters = this.$helpers.fixFilterSpaces(
        this.$constants.FORM_TAGS_FILTER,
        this.filters,
      );
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
    updateFilterButtonState() {
      this.isFilterApplied =
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.FORM_TAGS_FILTER);
    },
  },
  mounted() {
    this.getTagGroups();
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
